import {
  createUseStyles
} from 'react-jss'
import FacebookIcon from './icons/sns/FacebookIcon'

const useStyles = createUseStyles(theme => ({
  container: {
    // height: 46,
    borderRadius: 4,
    backgroundColor: '#1877f2',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
  },
  icon: {
    height: 24,
    width: 24,
    marginTop: 8,
    marginRight: 8,
    marginBottom: 8,
    marginLeft: 12,
    backgroundImage: 'url(https://potluck-web.s3.ap-northeast-1.amazonaws.com/facebook-round.png)',
    backgroundSize: 'contain',
  },
  text: {
    flex: 1,
    fontSize: 14,
    fontWeight: '700',
    letterSpacing: .25,
    textAlign: 'left',
    color: '#FFFFFF',
  },
}))

const FacebookButton = ({ onClick }) => {
  const classes = useStyles()

  return (
    <div
      className={classes.container}
      onClick={onClick}>
      <div className={classes.icon} />
      <div className={classes.text}>
        Facebookアカウントでログイン
      </div>
    </div>
  )
}

export default FacebookButton
