import {
  getWallet,
  getFavorites,
  getUpcomingOrders,
  getShops,
  getSavedLocations,
  getDeliveryLocations,
} from '../redux/api/actions'
import promiseMap from './promiseMap'

const DEFAULT_OPTIONS = {
  loggedIn: true,
}

const loadBasicPotluckAppData = async ({ store }, options=DEFAULT_OPTIONS) => {
  let result

  if (options.loggedIn) {
    result = await promiseMap({
      wallet: store.dispatch(getWallet()),
      favorites: store.dispatch(getFavorites()),
      upcomingOrders: store.dispatch(getUpcomingOrders()),
      shops: store.dispatch(getShops()),
      locations: store.dispatch(getSavedLocations()),
      deliveryLocations: store.dispatch(getDeliveryLocations()),
    })
  } else {
    result = await promiseMap({
      shops: store.dispatch(getShops()),
    })
  }

  return result
}

export default loadBasicPotluckAppData
