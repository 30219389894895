import setCookieAndLoadProfile from '../utils/setCookieAndLoadProfile'
import redirectIfLoggedIn from '../utils/redirectIfLoggedIn'
import Login from '../containers/Login'

const LoginWrapped = props => (<Login {...props} />)

LoginWrapped.getInitialProps = async context => {
  await setCookieAndLoadProfile(context)

  redirectIfLoggedIn(context)

  return {}
}

export default LoginWrapped
