const SvgComponent = props => (
  <svg width={28} height={27} fill="none" {...props}>
    <path
      d="M27 25.507C27 26.33 26.33 27 25.507 27H18.63V16.541h3.508l.523-4.074h-4.038V9.863c0-1.177.33-1.986 2.022-1.986H22.8v-3.64c-.375-.052-1.655-.162-3.148-.162-3.11 0-5.244 1.897-5.244 5.383v3.009h-3.515v4.074h3.515V27H1.493C.67 27 0 26.33 0 25.507V1.493C0 .67.67 0 1.493 0h24.021c.824 0 1.493.67 1.493 1.493v24.014H27z"
      fill="#fff"
    />
  </svg>
)

export default SvgComponent
