import {
  useState,
} from 'react'
import Head from 'next/head'
import { withRouter } from 'next/router'
import {
  connect,
} from 'react-redux'
import {
  bindActionCreators,
} from 'redux'
import {
  createUseStyles
} from 'react-jss'
import classNames from 'classnames'
import BodyClassName from 'react-body-classname'
import {
  login,
  loginSns,
  getProfile,
} from '../redux/api/actions'
import setCookieAndLoadProfile from '../utils/setCookieAndLoadProfile'
import redirectIfLoggedIn from '../utils/redirectIfLoggedIn'
import loadLocation from '../utils/loadLocation'
import Channel from '../utils/Channel'
import Colors from '../utils/Colors'
import promiseMap from '../utils/promiseMap'
import loadBasicPotluckAppData from '../utils/loadBasicPotluckAppData'
import isEmail from '../utils/isEmail'
import isPhone from '../utils/isPhone'
import formatInternationalPhone from '../utils/formatInternationalPhone'
import Button from '../components/Button'
import FacebookButton from '../components/FacebookButton'
import Logo from '../components/Logo'
import Footer from '../components/Footer'

const getFBLoginStatus = () => new Promise(resolve => {
  FB.getLoginStatus(response => resolve(response))
})

const loginWithFB = () => new Promise(resolve => {
  FB.login(response => resolve(response))
})

const useStyles = createUseStyles(theme => ({
  body: {
  },
  container: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  containerDialog: {
    minHeight: 'calc(100vh - 44px)',
    backgroundColor: Colors.backgroundGray,
    [theme.breakpoints.up('sm')]: {
      backgroundColor: 'transparent',
      minHeight: 'auto',
    }
  },
  content: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    position: 'relative',
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 4,
    [theme.breakpoints.down('xs')]: {
      paddingTop: 0,
    }
  },
  contentBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: 'url(https://s3-ap-northeast-1.amazonaws.com/potluck-webapp/shopentry/fv_img.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundColor: Colors.backgroundGray,
      backgroundImage: 'none',
      '&:after': {
        display: 'none',
      },
    },
  },
  contentBox: {
    position: 'relative',
    zIndex: 10,
    width: '100%',
    maxWidth: 400,
    margin: '0 auto',
    textAlign: 'center',
    backgroundColor: Colors.backgroundGray,
    borderRadius: 8,
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      borderRadius: 0,
      maxWidth: 'none',
    }
  },
  top: {
    backgroundImage: 'url(https://potluck-web.s3.ap-northeast-1.amazonaws.com/login/login-background.jpg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 160,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: 156,
    height: 50,
  },
  topShop: {
    height: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: `1px solid ${Colors.subtleGray}`,
  },
  logoShop: {
    width: 240,
    height: 50,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 8,
  },
  sns: {
    width: '100%',
    maxWidth: 274,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  dividerContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
  },
  divider: {
    flex: 1,
    borderBottom: `1px solid ${Colors.secondaryGray}`
  },
  dividerText: {
    color: Colors.primaryCharcoal,
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  error: {
    fontSize: 12,
    fontWeight: '600',
    color: Colors.alertRed,
    marginBottom: theme.spacing.unit * 2,
  },
  input: {
    minHeight: 48,
    fontSize: 16,
    borderWidth: 1,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${Colors.subtleGray}`,
    borderRadius: 3,
    paddingLeft: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    '&::placeholder': {
      color: Colors.subtleGray,
      fontSize: 14,
    },
  },
  primaryCta: {
    marginTop: theme.spacing.unit * 2,
  },
  secondaryCta: {
    marginTop: theme.spacing.unit * 3,
    textAlign: 'center',
    fontSize: '14px',
    lineHeight: '21px',
    color: Colors.secondaryCharcoal,
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  secondaryCtaPink: {
    color: Colors.primaryPink,
    textDecoration: 'none',
    fontWeight: '600',
  },
  poweredByPotluck: {
    position: 'fixed',
    height: 25,
    bottom: theme.spacing.unit * 2,
    left: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
    backgroundImage: 'url(https://potluck-web.s3.ap-northeast-1.amazonaws.com/powered-by-potluck.svg)',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    filter: 'invert(43%) sepia(50%) saturate(1776%) hue-rotate(324deg) brightness(111%) contrast(107%)',
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
    }
  }
}))

const Login = props => {
  const classes = useStyles()

  const [error, setError] = useState()
  const [phoneLoginLoading, setPhoneLoginLoading] = useState()
  const [emailOrPhone, setEmailOrPhone] = useState('')
  const [password, setPassword] = useState('')

  const phoneLoginValid = (isEmail(emailOrPhone) || isPhone(emailOrPhone)) && password.length > 0

  const onChangeEmailOrPhone = event => {
    setError(false)
    setEmailOrPhone(event.target.value)
  }

  const onChangePassword = event => {
    setError(false)
    setPassword(event.target.value)
  }

  const onClickLoginWithFacebook = async () => {
    const loginStatus = await getFBLoginStatus()

    let token

    if (loginStatus.status === 'connected') {
      token = loginStatus.authResponse.accessToken
    } else {
      const loginResult = await loginWithFB()

      token = loginResult?.authResponse?.accessToken
    }

    if (token) {
      const result = await props.loginSns({
        token,
        provider: 'facebook',
      })

      if (result && result.status >= 300) {
        setError('SNSアカウントが見つかりませんでした。再度お試しください。')
      } else {
        loadLocation(props.dispatch)
        const profile = await props.getProfile()

        if (!props.isShopApp) {
          Channel.boot(profile)
        }

        /*
         * TODO: find a better way than this fake store hack
         */
        await loadBasicPotluckAppData({
          store: {
            dispatch: props.dispatch,
          },
        })

        if (props.onSuccess) {
          props.onSuccess()
        } else {
          props.router.push('/menu')
        }
      }
    }
  }

  const onClickLoginWithEmailOrPhone = async () => {
    setPhoneLoginLoading(true)

    try {
      if (isEmail(emailOrPhone)) {
        await props.login({
          email: emailOrPhone,
          password,
        })
      } else if (isPhone(emailOrPhone)) {
        const phone = formatInternationalPhone({
          phone: emailOrPhone,
        })

        await props.login({
          phone,
          password,
        })
      }

      loadLocation(props.dispatch)
      const profile = await props.getProfile()

      if (!props.isShopApp) {
        Channel.boot(profile)
      }

      /*
       * TODO: find a better way than this fake store hack
       */
      await loadBasicPotluckAppData({
        store: {
          dispatch: props.dispatch,
        },
      })

      if (props.onSuccess) {
        await props.navigation.pop()
        props.onSuccess()
      } else {
        props.router.push('/menu')
      }
    } catch (error) {
      if (isEmail(emailOrPhone)) {
        setError('メールアドレスかパスワード、どちらかが正しくありません。')
      } else {
        setError('電話番号が正しくありません。')
      }
    } finally {
      setPhoneLoginLoading(false)
    }
  }

  const onClickSignup = async () => {
    if (props.onClickSignup) {
      await props.navigation.pop()
      props.onClickSignup()
    } else {
      props.router.push('/signup')
    }
  }

  return (
    <BodyClassName className={classNames({
      'shop-page-onsen-hack--dialog': props.dialog,
    })}>
      <div className={classNames(classes.container, {
        [classes.containerDialog]: props.dialog,
      })}>
        <Head>
          <title>ログイン</title>
          <script
            async
            defer
            crossOrigin="anonymous"
            src="https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v11.0&appId=413568586131897&autoLogAppEvents=1"
            nonce="aw2Wx1V9">
          </script>
          <script dangerouslySetInnerHTML={{
              __html: `
                window.fbAsyncInit = function() {
                  FB.init({
                    appId            : '413568586131897',
                    autoLogAppEvents : true,
                    xfbml            : true,
                    version          : 'v11.0'
                  });
                };
              `
            }} />
        </Head>
        <div className={classes.content}>
          {!props.dialog && (
            <div className={classes.contentBackground} />
          )}
          <div className={classes.contentBox}>
            {props.shop ? (
              <div className={classes.topShop}>
                <div
                  className={classes.logoShop}
                  style={{
                    backgroundImage: `url(${props.shop?.media?.logoWide || props.shop?.media?.logo})`
                  }} />
              </div>
            ) : (
              <div className={classes.top}>
                <Logo className={classes.logo} />
              </div>
            )}
            <form className={classes.form}>
              <div className={classes.sns}>
                <FacebookButton onClick={onClickLoginWithFacebook} />
              </div>
              <div className={classes.dividerContainer}>
                <div className={classes.divider} />
                <div className={classes.dividerText}>
                  または
                </div>
                <div className={classes.divider} />
              </div>
              {error && (
                <div className={classes.error}>
                  {error}
                </div>
              )}
              <input
                value={emailOrPhone}
                name="emailOrPhone"
                placeholder="メールもしくは電話番号"
                className={classes.input}
                onChange={onChangeEmailOrPhone} />
              <input
                value={password}
                name="password"
                type="password"
                placeholder="パスワード"
                className={classes.input}
                onChange={onChangePassword} />
              <Button
                text="ログイン"
                type="button"
                loading={phoneLoginLoading}
                disabled={phoneLoginLoading || !phoneLoginValid}
                className={classes.primaryCta}
                onClick={onClickLoginWithEmailOrPhone} />
              <div
                className={classes.secondaryCta}
                onClick={onClickSignup}>
                会員登録はこちら
              </div>
              <div className={classNames(classes.secondaryCta, classes.secondaryCtaPink)}>
                パスワードをお忘れですか？
              </div>
              {props.shop && (
                <div className={classes.poweredByPotluck} />
              )}
            </form>
          </div>
        </div>
        {!props.shop && (
          <Footer />
        )}
      </div>
    </BodyClassName>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    login,
    loginSns,
    getProfile,
  }, dispatch),
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login))
